<template>
	<div v-html="content">
			
	</div>
</template>

<script>
	import axios from 'axios';
	//获取富文本文件
	const getRich  = (id) => axios.get("/open/rich/"+id);
	
	export default {
		data(){
			return {
				content:""
			};
		},
		created(){
			getRich(this.$route.params.id).then(res=>{
				if(res.data){
					this.content=res.data.content;
				}else{
					this.form.content=null;
				}
			});
		},
		mounted() {
			document.querySelector("html > head").innerText="Policy";
		}
	}
</script>

<style>
</style>